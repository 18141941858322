<template>
    <q-layout view="hhh lpr fff">
        <q-page-container>
            <q-page v-bind="$e.config.props.e_login_page" class="flex flex-center bg-gradient">
                <q-card v-bind="$e.config.props.e_login_card" style="width: 530px" class="shadow-10 q-pa-xl q-ma-md">
                    <q-card-section class="q-pa-none">
                        <form>
                            <q-img
                                :src="publicPath + 'assets/images/enforb.png'"
                                loading="lazy"
                                spinner-color="grey-7"
                                fit="contain"
                                style="height: 100px"
                                class="q-mb-xl"
                            />
                            <q-input
                                v-bind="$e.config.props.e_login_input"
                                label="Meno"
                                type="text"
                                autocomplete="username"
                                v-model="userLogin"
                                :class="inputClass"
                                @keyup.enter="login"
                            >
                                <template v-slot:prepend>
                                    <q-icon name="person" color="grey-7" class="icon" />
                                </template>
                            </q-input>
                            <q-input
                                v-bind="$e.config.props.e_login_input"
                                label="Heslo"
                                type="password"
                                autocomplete="current-password"
                                v-model="userPassword"
                                :class="inputClass"
                                @keyup.enter="login"
                            >
                                <template v-slot:prepend>
                                    <q-icon name="lock" color="grey-7" class="icon" />
                                </template>
                            </q-input>
                            <q-btn
                                v-bind="$e.config.props.e_login_button"
                                color="grey-7"
                                text-color="grey-3"
                                type="button"
                                label="Prihlásiť"
                                unelevated
                                class="full-width q-pa-md q-mt-md q-mb-sm"
                                @click="login"
                            />
                        </form>
                    </q-card-section>
                </q-card>
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<script>
export default {
    name: 'LoginLayout',
    mounted() {
        setTimeout(() => (this.mounted = true), 1000);
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            mounted: false,
            userLogin: null,
            userPassword: null,
        };
    },
    computed: {
        inputClass() {
            return {
                'q-mb-sm': true,
                'autofill-fix': true,
                'autofill-fix-transition': this.mounted,
            };
        },
    },
    methods: {
        login() {
            this.$e.user.login(this.userLogin, this.userPassword, (user) => {
                this.$router.login();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.bg-gradient {
    background: rgb(230, 230, 230);
    background: linear-gradient(315deg, rgb(230, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 100%);
}
.bg-rainbow {
    background: rgb(238, 174, 202);
    background: linear-gradient(135deg, rgba(238, 174, 202, 0.5) 0%, rgba(148, 187, 233, 0.5) 100%);
}

:deep() .q-field.autofill-fix .q-field__control input.q-field__native {
    filter: none !important;
}
:deep() .q-field {
    &.autofill-fix-transition {
        input:-webkit-autofill {
            transition: box-shadow 0.36s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.36s cubic-bezier(0.4, 0, 0.2, 1);
        }
    }
    &.autofill-fix {
        input:-webkit-autofill {
            box-shadow: 0 0 0 100vw #d5d5d5 inset !important;
            -webkit-box-shadow: 0 0 0 100vw #d5d5d5 inset !important;
            margin-bottom: 1px;
        }
        inout:hover:-webkit-autofill {
            box-shadow: 0 0 0 100vw #d5d5d5 inset !important;
            -webkit-box-shadow: 0 0 0 100vw #d5d5d5 inset !important;
        }
    }
}
</style>

import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import LoginLayout from '../layouts/LoginLayout.vue';
import MainLayout from '../layouts/MainLayout.vue';

const routes = [
    {
        name: 'root',
        path: '/:pathMatch(.*)*',
        beforeEnter(to, from) {
            return false;
        },
    },
];
const loginRoutes = [{ name: 'login', path: '/:pathMatch(.*)*', component: LoginLayout }];
const mainRoutes = [
    {
        name: 'root',
        path: '/',
        component: MainLayout,
        // prettier-ignore
        children: [
            { name: 'main', path: '/', component: () => import(/* webpackChunkName: "main" */ '../views/MainView.vue') },
            { name: 'profile', path: '/profile', component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue') },
            { name: 'advice', path: '/advice', component: () => import(/* webpackChunkName: "advice" */ '../views/Advice/AdviceView.vue') },
            { name: 'advice-detail', path: '/advice/detail/:id', component: () => import(/* webpackChunkName: "advice-detail" */ '../views/Advice/AdviceDetailView.vue') },
            { name: 'advice-edit', path: '/advice/edit/:id?', component: () => import(/* webpackChunkName: "advice-edit" */ '../views/Advice/AdviceEditView.vue') },
            { name: 'analytics', path: '/analytics', component: () => import(/* webpackChunkName: "analytics" */ '../views/Analytics/AnalyticsView.vue') },
            { name: 'analytics-detail', path: '/analytics/detail/:id', component: () => import(/* webpackChunkName: "analytics-detail" */ '../views/Analytics/AnalyticsDetailView.vue') },
            { name: 'analytics-edit', path: '/analytics/edit/:id?', component: () => import(/* webpackChunkName: "analytics-edit" */ '../views/Analytics/AnalyticsEditView.vue') },
            { name: 'analytics-charts', path: '/analytics_charts', component: () => import(/* webpackChunkName: "analytics-edit" */ '../views/Analytics/AnalyticsChartsView.vue') },
            { name: 'article', path: '/article', component: () => import(/* webpackChunkName: "article" */ '../views/Article/ArticleView.vue') },
            { name: 'article-detail', path: '/article/detail/:id', component: () => import(/* webpackChunkName: "article-detail" */ '../views/Article/ArticleDetailView.vue') },
            { name: 'article-edit', path: '/article/edit/:id?', component: () => import(/* webpackChunkName: "article-edit" */ '../views/Article/ArticleEditView.vue') },
            { name: 'article-image-detail', path: '/article/image/detail/:articleId/:id', component: () => import(/* webpackChunkName: "article-image-detail" */ '../views/Article/Image/ImageDetailView.vue') },
            { name: 'article-image-edit', path: '/article/image/edit/:articleId/:id?', component: () => import(/* webpackChunkName: "article-image-edit" */ '../views/Article/Image/ImageEditView.vue') },
            { name: 'article-video-detail', path: '/article/video/detail/:articleId/:id', component: () => import(/* webpackChunkName: "article-video-detail" */ '../views/Article/Video/VideoDetailView.vue') },
            { name: 'article-video-edit', path: '/article/video/edit/:articleId/:id?', component: () => import(/* webpackChunkName: "article-video-edit" */ '../views/Article/Video/VideoEditView.vue') },
            { name: 'article-file-detail', path: '/article/file/detail/:articleId/:id', component: () => import(/* webpackChunkName: "article-file-detail" */ '../views/Article/File/FileDetailView.vue') },
            { name: 'article-file-edit', path: '/article/file/edit/:articleId/:id?', component: () => import(/* webpackChunkName: "article-file-edit" */ '../views/Article/File/FileEditView.vue') },
            { name: 'article_category', path: '/article_category', component: () => import(/* webpackChunkName: "article_category" */ '../views/ArticleCategory/ArticleCategoryView.vue') },
            { name: 'article_category-detail', path: '/article_category/detail/:id', component: () => import(/* webpackChunkName: "article_category-detail" */ '../views/ArticleCategory/ArticleCategoryDetailView.vue') },
            { name: 'article_category-edit', path: '/article_category/edit/:id?', component: () => import(/* webpackChunkName: "article_category-edit" */ '../views/ArticleCategory/ArticleCategoryEditView.vue') },
            { name: 'client', path: '/client', component: () => import(/* webpackChunkName: "client" */ '../views/Client/ClientView.vue') },
            { name: 'client-detail', path: '/client/detail/:id', component: () => import(/* webpackChunkName: "client-detail" */ '../views/Client/ClientDetailView.vue'), meta: { persistent: ['invoiceId', 'invoiceTab', 'orderId', 'orderTab'] } },
            { name: 'client-edit', path: '/client/edit/:id?', component: () => import(/* webpackChunkName: "client-edit" */ '../views/Client/ClientEditView.vue'), meta: { persistent: ['invoiceId', 'invoiceTab', 'orderId', 'orderTab'] } },
            { name: 'country', path: '/country', component: () => import(/* webpackChunkName: "country" */ '../views/Country/CountryView.vue') },
            { name: 'country-detail', path: '/country/detail/:id', component: () => import(/* webpackChunkName: "country-detail" */ '../views/Country/CountryDetailView.vue') },
            { name: 'country-edit', path: '/country/edit/:id?', component: () => import(/* webpackChunkName: "country-edit" */ '../views/Country/CountryEditView.vue') },
            { name: 'delivery_method', path: '/delivery_method', component: () => import(/* webpackChunkName: "delivery_method" */ '../views/DeliveryMethod/DeliveryMethodView.vue') },
            { name: 'delivery_method-detail', path: '/delivery_method/detail/:id', component: () => import(/* webpackChunkName: "delivery_method-detail" */ '../views/DeliveryMethod/DeliveryMethodDetailView.vue') },
            { name: 'delivery_method-edit', path: '/delivery_method/edit/:id?', component: () => import(/* webpackChunkName: "delivery_method-edit" */ '../views/DeliveryMethod/DeliveryMethodEditView.vue') },
            { name: 'express_equip', path: '/express_equip', component: () => import(/* webpackChunkName: "express_equip" */ '../views/ExpressEquip/ExpressEquipView.vue') },
            { name: 'express_equip-detail', path: '/express_equip/detail/:id', component: () => import(/* webpackChunkName: "express_equip-detail" */ '../views/ExpressEquip/ExpressEquipDetailView.vue') },
            { name: 'express_equip-edit', path: '/express_equip/edit/:id?', component: () => import(/* webpackChunkName: "express_equip-edit" */ '../views/ExpressEquip/ExpressEquipEditView.vue') },
            { name: 'gallery', path: '/gallery', component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery/GalleryView.vue') },
            { name: 'gallery-detail', path: '/gallery/detail/:id', component: () => import(/* webpackChunkName: "gallery-detail" */ '../views/Gallery/GalleryDetailView.vue') },
            { name: 'gallery-edit', path: '/gallery/edit/:id?', component: () => import(/* webpackChunkName: "gallery-edit" */ '../views/Gallery/GalleryEditView.vue') },
            { name: 'gallery-gallery_entry-detail', path: '/gallery/gallery_entry/detail/:galleryId/:id', component: () => import(/* webpackChunkName: "gallery-gallery_entry-detail" */ '../views/Gallery/GalleryEntry/GalleryEntryDetailView.vue') },
            { name: 'gallery-gallery_entry-edit', path: '/gallery/gallery_entry/edit/:galleryId/:id?', component: () => import(/* webpackChunkName: "gallery-gallery_entry-edit" */ '../views/Gallery/GalleryEntry/GalleryEntryEditView.vue') },
            { name: 'invoice', path: '/invoice', component: () => import(/* webpackChunkName: "invoice" */ '../views/Invoice/InvoiceView.vue') },
            { name: 'invoice-detail', path: '/invoice/detail/:id', component: () => import(/* webpackChunkName: "invoice-detail" */ '../views/Invoice/InvoiceDetailView.vue'), meta:{ persistent: ['orderId', 'orderTab'] } },
            { name: 'invoice-edit', path: '/invoice/edit/:id?', component: () => import(/* webpackChunkName: "invoice-edit" */ '../views/Invoice/InvoiceEditView.vue'), meta:{ persistent: ['orderId', 'orderTab'] } },
            { name: 'mail_pattern', path: '/mail_pattern', component: () => import(/* webpackChunkName: "mail_pattern" */ '../views/MailPattern/MailPatternView.vue') },
            { name: 'mail_pattern-detail', path: '/mail_pattern/detail/:id', component: () => import(/* webpackChunkName: "mail_pattern-detail" */ '../views/MailPattern/MailPatternDetailView.vue') },
            { name: 'mail_pattern-edit', path: '/mail_pattern/edit/:id?', component: () => import(/* webpackChunkName: "mail_pattern-edit" */ '../views/MailPattern/MailPatternEditView.vue') },
            { name: 'notice', path: '/notice', component: () => import(/* webpackChunkName: "notice" */ '../views/Notice/NoticeView.vue') },
            { name: 'notice-detail', path: '/notice/detail/:id', component: () => import(/* webpackChunkName: "notice-detail" */ '../views/Notice/NoticeDetailView.vue') },
            { name: 'notice-edit', path: '/notice/edit/:id?', component: () => import(/* webpackChunkName: "notice-edit" */ '../views/Notice/NoticeEditView.vue') },
            { name: 'order', path: '/order', component: () => import(/* webpackChunkName: "order" */ '../views/Order/OrderView.vue') },
            { name: 'order-detail', path: '/order/detail/:id', component: () => import(/* webpackChunkName: "order-detail" */ '../views/Order/OrderDetailView.vue'), meta: { persistent: ['invoiceId', 'invoiceTab'] } },
            { name: 'order-edit', path: '/order/edit/:id?', component: () => import(/* webpackChunkName: "order-edit" */ '../views/Order/OrderEditView.vue'), meta: { persistent: ['invoiceId', 'invoiceTab'] } },
            { name: 'order-file-detail', path: '/order/file/detail/:orderId/:id', component: () => import(/* webpackChunkName: "order-file-detail" */ '../views/Order/File/FileDetailView.vue'), meta: { persistent: ['invoiceId', 'invoiceTab'] } },
            { name: 'order-file-edit', path: '/order/file/edit/:orderId/:id?', component: () => import(/* webpackChunkName: "order-file-edit" */ '../views/Order/File/FileEditView.vue'), meta: { persistent: ['invoiceId', 'invoiceTab'] } },
            { name: 'print_pattern', path: '/print_pattern', component: () => import(/* webpackChunkName: "print_pattern" */ '../views/PrintPattern/PrintPatternView.vue') },
            { name: 'print_pattern-detail', path: '/print_pattern/detail/:id', component: () => import(/* webpackChunkName: "print_pattern-detail" */ '../views/PrintPattern/PrintPatternDetailView.vue') },
            { name: 'print_pattern-edit', path: '/print_pattern/edit/:id?', component: () => import(/* webpackChunkName: "print_pattern-edit" */ '../views/PrintPattern/PrintPatternEditView.vue') },
            { name: 'product', path: '/product', component: () => import(/* webpackChunkName: "product" */ '../views/Product/ProductView.vue') },
            { name: 'product-detail', path: '/product/detail/:id', component: () => import(/* webpackChunkName: "product-detail" */ '../views/Product/ProductDetailView.vue') },
            { name: 'product-edit', path: '/product/edit/:id?', component: () => import(/* webpackChunkName: "product-edit" */ '../views/Product/ProductEditView.vue') },
            { name: 'reference', path: '/reference', component: () => import(/* webpackChunkName: "reference" */ '../views/Reference/ReferenceView.vue') },
            { name: 'reference-detail', path: '/reference/detail/:id', component: () => import(/* webpackChunkName: "reference-detail" */ '../views/Reference/ReferenceDetailView.vue') },
            { name: 'reference-edit', path: '/reference/edit/:id?', component: () => import(/* webpackChunkName: "reference-edit" */ '../views/Reference/ReferenceEditView.vue') },
            { name: 'setting', path: '/setting', redirect: '/setting/detail' },
            { name: 'setting-detail', path: '/setting/detail', component: () => import(/* webpackChunkName: "setting-detail" */ '../views/Setting/SettingDetailView.vue') },
            { name: 'setting-edit', path: '/setting/edit', component: () => import(/* webpackChunkName: "setting-edit" */ '../views/Setting/SettingEditView.vue') },
            { name: 'sms_pattern', path: '/sms_pattern', component: () => import(/* webpackChunkName: "sms_pattern" */ '../views/SmsPattern/SmsPatternView.vue') },
            { name: 'sms_pattern-detail', path: '/sms_pattern/detail/:id', component: () => import(/* webpackChunkName: "sms_pattern-detail" */ '../views/SmsPattern/SmsPatternDetailView.vue') },
            { name: 'sms_pattern-edit', path: '/sms_pattern/edit/:id?', component: () => import(/* webpackChunkName: "sms_pattern-edit" */ '../views/SmsPattern/SmsPatternEditView.vue') },
            { name: 'subpage', path: '/subpage', component: () => import(/* webpackChunkName: "subpage" */ '../views/Subpage/SubpageView.vue') },
            { name: 'subpage-detail', path: '/subpage/detail/:id', component: () => import(/* webpackChunkName: "subpage-detail" */ '../views/Subpage/SubpageDetailView.vue') },
            { name: 'subpage-edit', path: '/subpage/edit/:id?', component: () => import(/* webpackChunkName: "subpage-edit" */ '../views/Subpage/SubpageEditView.vue') },
            { name: 'subpage-image-detail', path: '/subpage/image/detail/:subpageId/:id', component: () => import(/* webpackChunkName: "subpage-image-detail" */ '../views/Subpage/Image/ImageDetailView.vue') },
            { name: 'subpage-image-edit', path: '/subpage/image/edit/:subpageId/:id?', component: () => import(/* webpackChunkName: "subpage-image-edit" */ '../views/Subpage/Image/ImageEditView.vue') },
            { name: 'user', path: '/user', component: () => import(/* webpackChunkName: "user" */ '../views/User/UserView.vue') },
            { name: 'user-detail', path: '/user/detail/:id', component: () => import(/* webpackChunkName: "user-detail" */ '../views/User/UserDetailView.vue') },
            { name: 'user-edit', path: '/user/edit/:id?', component: () => import(/* webpackChunkName: "user-edit" */ '../views/User/UserEditView.vue') },
        ],
    },
    { name: 'catch', path: '/:catchAll(.*)*', redirect: '/' },
];

const router = createRouter({
    history: process.env.VUE_APP_ROUTER_MODE == 'hash' ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    // console.log('beforeEach', from, to);
    // return false;
    if (to.meta && to.meta.persistent && !!Object.keys(from.query).length) {
        let success = false;
        for (let i = 0; i < to.meta.persistent.length; i++) {
            const persistent = to.meta.persistent[i];
            if (from.query[persistent] && !to.query[persistent]) {
                to.query[persistent] = from.query[persistent];
                success = true;
            }
        }
        if (success) {
            next(to);
        } else {
            next();
        }
    } else {
        next();
    }
});
router.beforeResolve((to, from) => {
    // console.log('beforeResolve', from, to);
    // return false;
});
router.afterEach((to, from) => {
    // console.log('afterEach', from, to);
    // return false;
});

router.addRoutes = function (routes) {
    router.options.routes = routes;
    routes.forEach((route) => {
        router.addRoute(route);
    });
};
router.removeRoutes = function () {
    router.options.routes.forEach((route) => {
        router.removeRoute(route.name);
    });
    router.options.routes = [];
};
router.reload = function () {
    const path = window.location.href.replace(window.location.origin + router.options.history.base, '');
    router.replace(router.resolve(path));
};
router.login = function () {
    router.removeRoutes();
    router.addRoutes(mainRoutes);
    router.reload();
};
router.logout = function () {
    router.removeRoutes();
    router.addRoutes(loginRoutes);
    router.reload();
};

export default router;

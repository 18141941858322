import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import { EnfisQuasar } from '@enfis/quasar';
import skin from './styles/skin/skin.js';

createApp(App)
    .use(Quasar, quasarUserOptions)
    .use(i18n)
    .use(store)
    .use(router)
    .use(EnfisQuasar, {
        config: {
            io: { baseURL: window.config.enfisBaseUrl },
            user: {
                roles: {
                    ROLE_USERS_MANAGER: 1,
                    ROLE_SETTINGS_MANAGER: 2,
                    ROLE_MAIL_PATTERN_MANAGER: 3,
                    ROLE_SMS_PATTERN_MANAGER: 4,
                    ROLE_PRINT_PATTERN_MANAGER: 5,
                    ROLE_ADVICES_MANAGER: 6,
                    ROLE_ARTICLES_MANAGER: 7,
                    ROLE_CLIENTS_MANAGER: 8,
                    ROLE_GALLERIES_MANAGER: 9,
                    ROLE_SUBPAGES_MANAGER: 10,
                    ROLE_NOTICES_MANAGER: 11,
                    ROLE_REFERENCES_MANAGER: 12,
                    ROLE_ANALYTICS_MANAGER: 13,
                    ROLE_DELIVERY_METHODS_MANAGER: 14,
                    ROLE_EXPRESS_EQUIP_MANAGER: 15,
                    ROLE_PRODUCTS_MANAGER: 16,
                    ROLE_ORDERS_MANAGER: 17,
                },
            },
            loading: {
                spinnerColor: skin.e_color,
            },
            props: {
                ...skin,
            },
        },
    })
    .mount('#app');
